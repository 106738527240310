import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import logo from '../../../images/logo.svg';
import headerImg from '../../../images/header-promo-img.jpg';
import headerImgLCC from '../../../images/lcc-members-badge.png';
import sra from '../../../images/sra.png';
import MainGrid from '../../styling/MainGrid';
import theme from '../../styling/theme';

const stickyNavigation = [
  { title: 'R&D Tax Credit', slug: '/tax-credit' },
  { title: 'Claims Process', slug: '/claims-process' },
  { title: 'Industry Sector', slug: '/industry-sector' },
  { title: 'Sponsorships', slug: '/sponsorships' },
  { title: 'About us', slug: '/about-us' },
  { title: 'Blog', slug: 'https://blog.rda.co.uk' },
  { title: 'Contact', slug: '/contact-us' },
];

interface NavigationProps {
  activeLink?: string;
}

const Navigation: FunctionComponent<NavigationProps> = ({ activeLink }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleSraClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Container>
      <TopNav>
        <MainGrid noPaddingMobile noPaddingTablet>
          <TopNavInner>
            <div className="logo-container">
              <a href={process.env.BASE}>
                <figure className="image">
                  <img src={logo} alt="R&D Logo" style={{ height: '85px', width: 'auto' }} />
                </figure>
              </a>
            </div>
          </TopNavInner>
        </MainGrid>
      </TopNav>
      <MainGrid noPaddingMobile>
        <StickyNav>
          {stickyNavigation.map((edge, key) => (
            <StyledLink
              to={(process.env.BASE === '/' ? '' : process.env.BASE) + edge.slug}
              key={key}
              firstLink={key === 0}
              activeLink={activeLink === edge.slug}
            >
              {edge.title}
            </StyledLink>
          ))}
        </StickyNav>
      </MainGrid>
      <NewsBanner>
        <MainGrid>
          <NewsItem>
            <a href="https://www.lincs-chamber.co.uk/members/rda-uk-ltd/" target="_blank" rel="noopener noreferrer">
              <img src={headerImgLCC} alt="LCC Event Image" />
              <span>LCC Membership - Learn More</span>
            </a>
          </NewsItem>
          <NewsItem>
            <a href="https://www.the-mtc.org/events/industry-events/meet-make-manufacture/" target="_blank" rel="noopener noreferrer">
              <img src={headerImg} alt="Event Image" />
              <span>Meet, Make, Manufacture - Register Now</span>
            </a>
          </NewsItem>
          <NewsItem>
            <a href="#" onClick={handleSraClick}>
              <img src={sra} alt="SRA Image" />
              <span>Partnership with Stuart Rivers Associates</span>
            </a>
          </NewsItem>
        </MainGrid>
      </NewsBanner>
      {isModalOpen && <Modal onClose={closeModal} />}
    </Container>
  );
};

const Modal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <h3>RDA is excited to now be in partnership with Stuart Rivers Associates</h3>
        <p>
          Stuart Rivers Associates is a premium firm of capital allowances consultants based in Wetherby covering the whole of the UK and Europe.
        </p>
        <p>
          They work closely with accountants, tax advisers, solicitors, investors, developers, and others to maximize capital allowances tax savings.
        </p>
        <p>
          As capital allowance consultants, Stuart Rivers Associates has been helping clients make claims for over 30 years.
        </p>
        <p>
          Stuart Rivers, their founder, was an early adopter for capital allowances and helped develop the market for property capital allowance claims in the UK so their claim to be experts in the field is rightly justified.
        </p>
        <p>
          To find out more, go to: <a href="https://stuart-rivers.co.uk/" target="_blank" rel="noopener noreferrer">https://stuart-rivers.co.uk/</a>
        </p>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Navigation;

const Container = styled.nav`
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  display: none;
  @media screen and (min-width: 1024px) {
    padding: 0;
    display: flex;
  }
`;

const TopNav = styled.div`
  border-bottom: 1px solid ${theme.grey.keyline};
`;

const TopNavInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0 1.3rem;
`;

const StyledLink = styled(props => <Link {...props} />)`
  &,
  &:link,
  &:visited {
    color: ${theme.text.main};
    font-family: Roboto;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    position: relative;
    text-decoration: none !important;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: ${props => (props.firstLink ? '0 2rem 0 0' : '0 2rem')};
    font-weight: 400;

    &::after {
      position: absolute;
      content: ' ';
      height: 3px;
      width: 80%;
      bottom: -1px;
      left: ${props => (props.firstLink ? '0' : '10%')};
      background: ${theme.brand.blue};
      transition: ease-out 0.3s;
      margin: 0 auto;
      transform: scaleX(0);
      transform: ${props => (props.activeLink ? 'scaleX(1)' : 'scaleX(0)')};
    }

    &:hover::after,
    &:active::after,
    &:focus::after {
      transform: scaleX(1);
    }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
  }
`;

const StickyNav = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
  }
`;

const NewsBanner = styled.div`
  background: ${theme.brand.lightGrey};
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid ${theme.grey.keyline};
  border-bottom: 1px solid ${theme.grey.keyline};

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }
`;

const NewsItem = styled.div`
  display: inline-block;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 5px;
  padding: 0 10px;
  width: 30%; /* Adjust width to fit 3 items horizontally */

  @media screen and (max-width: 1023px) {
    width: 100%; /* Full width on mobile */
    border-left: none;
  }

  @media screen and (min-width: 1024px) {
    border-left: 1px solid ${theme.grey.keyline}; /* Light grey line between items */
    &:first-child {
      border-left: none; /* Remove border for the first item */
    }
  }

  a {
    text-decoration: none;
    color: ${theme.text.main};
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 40px;
      width: auto;
      margin-bottom: 5px; /* Add some space between the image and text */
    }

    span {
      font-size: 12px; /* Smaller font size for text */
      font-weight: bold;
    }
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  border-radius: 8px;
  text-align: left;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;
